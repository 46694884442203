import React, { useEffect, useState, memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadPages } from "../../../../actions/navigationAction";
import { getAssemblyVersion } from "../../../../actions/settingActions";
import SentryRoute from "../../../../helpers/sentry";
import { getPages } from "../../../core/pages/pages";
import { PrivateRoute } from "../../../core/privateRoute/privateRoute";
import { PrivateRouteAdmin } from "../../../core/privateRoute/privateRouteAdmin";
import AllCookingProcessesOverviewCustomer from "../../../pages/cookingProcess/allCookingProcessesOverviewCustomer";
import CookingProcess from "../../../pages/cookingProcess/cookingProcess";
import CookingProcessIngredientList from "../../../pages/cookingProcess/cookingProcessIngredientList";
import Login from "../../../pages/login/login";
import Register from "../../../pages/register/register";
import DynamicPage from "./dynamicPage";
import "./page.scss";

const Page = memo(() => {
    const [allNavigationTiles, setAllNavigationTiles] = useState([]);
    const dispatch = useDispatch();
    const { pages } = useSelector((state) => state.navigation);
    const { user } = useSelector((state) => state.identity);

    const initializePages = useCallback(() => {
        const pages = getPages();
        dispatch(loadPages(pages));
        dispatch(getAssemblyVersion());

        const newNavigationTiles = pages.reduce((acc, page) => {
            return page.navigationTiles ? [...acc, ...page.navigationTiles] : acc;
        }, []);

        setAllNavigationTiles(newNavigationTiles);
    }, [dispatch]);

    useEffect(() => {
        initializePages();
    }, []); // Initial load

    useEffect(() => {
        if (user.Username) {
            initializePages();
        }
    }, [user.Username, initializePages]); // Re-initialize when username changes

    return (
        <div className="pages">
            {/* <PrivateRoute exact path="/oldHome" component={Home} /> */}
            {/*FIXME change to PrivateRoute*/}
            <SentryRoute path="/login" component={Login} />
            <PrivateRoute path="/dienstplan" component="" />
            <PrivateRoute path="/fibuexport" component="" />
            <PrivateRoute path="/gutscheinsystem" component="" />
            <PrivateRoute path="/newsletter" component="" />
            <PrivateRoute path="/onlineshop" component="" />
            <PrivateRoute path="/protokolle" component="" />
            {/* <SentryRoute path="/speisekarteplus" component={ArticleManagement} /> */}
            {/* <PrivateRouteAdmin path="/verwaltung" component={Administration} /> */}
            {/* <PrivateRoute path="/bestelluebersicht" forbidden={!hasRight(viewOrders)} component={OrderOverview} />
                <PrivateRoute
                    path="/dailyOrders"
                    forbidden={!hasRight(viewDailyOrders)}
                    component={DailyOrderOverview}
                /> */}
            <PrivateRouteAdmin path="/register" component={Register} />
            {/* <PrivateRoute exact path="/rezepte" forbidden={!hasRight(viewRecipes)} component={RecipesOverview} />
                <PrivateRoute exact path="/rezepte/:id" forbidden={!hasRight(viewRecipes)} component={Recipe} />
                <PrivateRoute
                    exact
                    path="/wochenplaene"
                    forbidden={!hasRight(viewWeeklyPlans)}
                    component={WeeklyPlans}
                />
                <PrivateRoute
                    exact
                    path="/kochwochenplaene"
                    forbidden={!hasRight(viewWeeklyPlans)}
                    component={WeeklyPlans}
                />
                <PrivateRoute path="/synchronisierung" component={Sync} />
                <PrivateRoute exact path="/syncHistory" component={SyncHistory} />
                <PrivateRoute path="/dashboards" forbidden={!hasRight(viewDashboards)} component={Dashboard} />
                <PrivateRoute
                    path="/allCookingProcesses/:type/:parentId?"
                    forbidden={!hasRight(viewCookingProcesses)}
                    component={AllCookingProcessesOverview}
                />
                <PrivateRoute path="/orderManagement" forbidden={!hasRight(createOrders)} component={OrderManagement} />
                <PrivateRoute path={`/impressum`} component={Impressum} />
                <PrivateRoute path="/dishes" forbidden={!hasRight(createCookingProcesses)} component={Dishes} />
                <PrivateRoute
                    path="/cookingProcess/:id/:phasesId/:stepsId"
                    forbidden={!hasRight(viewCookingProcesses)}
                    component={CookingProcess}
                    exact
                />
                <PrivateRoute
                    path="/cookingProcessIngredientList/:id/:phasesId/:stepsId"
                    forbidden={!hasRight(viewCookingProcesses)}
                    component={CookingProcessIngredientList}
                />
                <PrivateRoute path="/mediathek" component={Media} />
                <PrivateRoute path="/verpackung" forbidden={!hasRight(viewPackings)} component={Packaging} />
            <PrivateRoute path="/uebersetzung" component={Translation} /> */}
            {/* Public Routes */}
            {/* <PrivateRoute path="/theming" component={Theming} /> */}
            <SentryRoute
                path="/guest/allCookingProcessesCustomer/:customersUid/:type?/:parentId?"
                component={AllCookingProcessesOverviewCustomer}
            />
            <SentryRoute
                path="/guest/cookingProcess/:id/:phasesId/:stepsId/:customersUid"
                component={CookingProcess}
                exact
            />
            <SentryRoute
                path="/guest/cookingProcessIngredientList/:id/:phasesId/:stepsId/:customersUid"
                component={CookingProcessIngredientList}
            />

            {pages.map((page, index) => (
                <PrivateRoute
                    key={index}
                    path={page.route}
                    exact={page.exact}
                    hasRight={page.hasRight}
                    component={(props) => <DynamicPage page={page} {...props} />}
                />
            ))}

            {allNavigationTiles.map((page, index) =>
                page.component ? (
                    <PrivateRoute
                        key={index}
                        path={page.route}
                        hasRight={page.hasRight}
                        exact={page.exact}
                        component={(props) => <DynamicPage page={page} {...props} />}
                    />
                ) : (
                    <React.Fragment key={index} />
                )
            )}
        </div>
    );
});

Page.displayName = "Page";
export default Page;
