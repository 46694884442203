import store from "../store";

export function hasRight(neededRights, exact = false) {
    if (store) {
        let currentUser = store.getState().identity;
        let rightsResult = {
            inexact: false,
            exact: false,
        };

        if (currentUser) {
            let userRights = new Set(currentUser.user.Rights);
            let userHasRight = new Set([...neededRights].filter((neededRight) => userRights.has(neededRight)));

            rightsResult.inexact = userHasRight.size > 0 ? true : false;
            rightsResult.exact = userHasRight.size === neededRights.length ? true : false;
        }

        return exact ? rightsResult.exact : rightsResult.inexact;
    } else return false;
}

export function userIsSuperAdmin() {
    if (store) {
        let currentUser = store.getState().identity;
        if (currentUser.user.Roles) {
            return currentUser.user.Roles[0].role === "SuperAdmin";
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function userMinimumAdmin() {
    let currentUser = store.getState().identity;
    return currentUser.user.Roles[0].role === "SuperAdmin" || currentUser.user.Roles[0].role === "Admin";
}

// User Rights
export const createOrders = ["Rights.Orders.DoOrder.createOrders"];
export const viewOrders = ["Rights.Orders.OrderManagement.viewOrders"];
export const cancelOrders = ["Rights.Orders.OrderManagement.cancelOrders"];
export const editOrderState = ["Rights.Orders.OrderManagement.editOrderState"];
export const editPaymentType = ["Rights.Orders.OrderManagement.editPaymentType"];
export const cancelOrderItems = ["Rights.Orders.OrderManagement.cancelOrderItems"];
export const viewDailyOrders = ["Rights.Orders.DailyOrders.viewDailyOrders"];
export const printDailyOrders = ["Rights.Orders.DailyOrders.printDailyOrders"];
export const createCookingProcess = ["Rights.Orders.DailyOrders.createCookingProcess"];
export const viewManagement = ["Rights.Management"];
//export const viewAdministration = ["Rights.Management.Administration"];
export const viewKitchenManagement = ["Rights.Management.KitchenManagement"];
export const CheckoutManagement = ["Rights.Management.CheckoutManagement"];
export const viewSettings = ["Rights.Management.Settings"];
//export const viewMenuCardPlus = ["Rights.Management.Administration.Settings.MenuCardPlus"];
export const viewTools = ["Rights.Management.KitchenManagement.Tools.viewTools"];
export const addTools = ["Rights.Management.KitchenManagement.Tools.addTools"];
export const updateTools = ["Rights.Management.KitchenManagement.Tools.updateTools"];
export const deleteTools = ["Rights.Management.KitchenManagement.Tools.deleteTools"];
export const viewWorkingstations = ["Rights.Management.KitchenManagement.Workingstations.viewWorkingstations"];
export const addWorkingstations = ["Rights.Management.KitchenManagement.Workingstations.addWorkingstations"];
export const updateWorkingstations = ["Rights.Management.KitchenManagement.Workingstations.updateWorkingstations"];
export const deleteWorkingstations = ["Rights.Management.KitchenManagement.Workingstations.deleteWorkingstations"];
export const viewArticleSizes = ["Rights.Management.KitchenManagement.ArticleSizes.viewArticleSizes"];
export const addArticleSizes = ["Rights.Management.KitchenManagement.ArticleSizes.addArticleSizes"];
export const updateArticleSizes = ["Rights.Management.KitchenManagement.updateArticleSizes"];
export const deleteArticleSizes = ["Rights.Management.KitchenManagement.ArticleSizes.deleteArticleSizes"];
export const viewOrderTypes = ["Rights.Management.KitchenManagement.OrderTypes.viewOrderTypes"];
export const addOrderTypes = ["Rights.Management.KitchenManagement.OrderTypes.addOrderTypes"];
export const updateOrderTypes = ["Rights.Management.KitchenManagement.OrderTypes.updateOrderTypes"];
export const deleteOrderTypes = ["Rights.Management.KitchenManagement.OrderTypes.deleteOrderTypes"];
export const viewArticleDependencyGroups = [
    "Rights.Management.KitchenManagement.ArticleDependencyGroups.viewArticleDependencyGroups",
];
export const addArticleDependencyGroups = [
    "Rights.Management.KitchenManagement.ArticleDependencyGroups.addArticleDependencyGroups",
];
export const updateArticleDependencyGroups = [
    "Rights.Management.KitchenManagement.ArticleDependencyGroups.updateArticleDependencyGroups",
];
export const deleteArticleDependencyGroups = [
    "Rights.Management.KitchenManagement.ArticleDependencyGroups.deleteArticleDependencyGroups",
];

export const viewPreparationLocations = [
    "Rights.Management.KitchenManagement.PreparationLocations.viewPreparationLocations",
];
export const addPreparationLocations = [
    "Rights.Management.KitchenManagement.PreparationLocations.addPreparationLocations",
];
export const updatePreparationLocations = [
    "Rights.Management.KitchenManagement.PreparationLocations.updatePreparationLocations",
];
export const deletePreparationLocations = [
    "Rights.Management.KitchenManagement.PreparationLocations.deletePreparationLocations",
];

export const viewDiets = ["Rights.Management.KitchenManagement.Diets.viewDiets"];
export const addDiets = ["Rights.Management.KitchenManagement.Diets.addDiets"];
export const updateDiets = ["Rights.Management.KitchenManagement.Diets.updateDiets"];
export const deleteDiets = ["Rights.Management.KitchenManagement.Diets.deleteDiets"];
export const viewCategories = ["Rights.Management.KitchenManagement.Categories.viewCategories"];
export const addCategories = ["Rights.Management.KitchenManagement.Categories.addCategories"];
export const updateCategories = ["Rights.Management.KitchenManagement.Categories.updateCategories"];
export const deleteCategories = ["Rights.Management.KitchenManagement.Categories.deleteCategories"];
export const viewPhaseTypes = ["Rights.Management.KitchenManagement.PhaseTypes.viewPhaseTypes"];
export const addPhaseTypes = ["Rights.Management.KitchenManagement.PhaseTypes.addPhaseTypes"];
export const updatePhaseTypes = ["Rights.Management.KitchenManagement.PhaseTypes.updatePhaseTypes"];
export const deletePhaseTypes = ["Rights.Management.KitchenManagement.PhaseTypes.deletePhaseTypes"];
export const viewPriceGroups = ["Rights.Management.CheckoutManagement.PriceGroups.viewPriceGroups"];
export const addPriceGroups = ["Rights.Management.CheckoutManagement.PriceGroups.addPriceGroups"];
export const updatePriceGroups = ["Rights.Management.CheckoutManagement.PriceGroups.updatePriceGroups"];
export const deletePriceGroups = ["Rights.Management.CheckoutManagement.PriceGroups.deletePriceGroups"];
export const viewTaxes = ["Rights.Management.CheckoutManagement.Taxes.viewTaxes"];
export const addTaxes = ["Rights.Management.CheckoutManagement.Taxes.addTaxes"];
export const updateTaxes = ["Rights.Management.CheckoutManagement.Taxes.updateTaxes"];
export const deleteTaxes = ["Rights.Management.CheckoutManagement.Taxes.deleteTaxes"];
export const viewArticleAvailabilities = [
    "Rights.Management.KitchenManagement.ArticleAvailabilities.viewArticleAvailabilities",
];
export const addArticleAvailabilities = [
    "Rights.Management.KitchenManagement.ArticleAvailabilities.addArticleAvailabilities",
];
export const updateArticleAvailabilities = [
    "Rights.Management.KitchenManagement.ArticleAvailabilities.updateArticleAvailabilities",
];
export const deleteArticleAvailabilities = [
    "Rights.Management.KitchenManagement.ArticleAvailabilities.deleteArticleAvailabilities",
];
export const viewWareTypes = ["Rights.Management.CheckoutManagement.WareTypes.viewWareTypes"];
export const addWareTypes = ["Rights.Management.CheckoutManagement.WareTypes.addWareTypes"];
export const updateWareTypes = ["Rights.Management.CheckoutManagement.WareTypes.updateWareTypes"];
export const deleteWareTypes = ["Rights.Management.CheckoutManagement.WareTypes.deleteWareTypes"];
export const allAdditivesAdministration = [
    "Rights.Management.KitchenManagement.AdditivesAdministration.allAdditivesAdministration",
];
export const viewAdditiveGroups = [
    "Rights.Management.KitchenManagement.AdditivesAdministration.AdditiveGroups.allAdditiveGroups",
];
export const addAdditiveGroups = [
    "Rights.Management.KitchenManagement.AdditivesAdministration.AdditiveGroups.addAdditiveGroups",
];
export const editAdditiveGroups = [
    "Rights.Management.KitchenManagement.AdditivesAdministration.AdditiveGroups.editAdditiveGroups",
];
export const deleteAdditiveGroups = [
    "Rights.Management.KitchenManagement.AdditivesAdministration.AdditiveGroups.deleteAdditiveGroups",
];
export const viewAdditives = ["Rights.Management.KitchenManagement.AdditivesAdministration.Additives.viewAdditives"];
export const addAdditives = ["Rights.Management.KitchenManagement.AdditivesAdministration.Additives.addAdditives"];
export const editAdditives = ["Rights.Management.KitchenManagement.AdditivesAdministration.Additives.editAdditives"];
export const deleteAdditives = [
    "Rights.Management.KitchenManagement.AdditivesAdministration.Additives.deleteAdditives",
];
export const viewTheming = ["Rights.Management.Settings.Theming.viewTheming"];
export const addTheming = ["Rights.Management.Settings.Theming.addTheming"];
export const updateTheming = ["Rights.Management.Settings.Theming.updateTheming"];
export const deleteTheming = ["Rights.Management.Settings.Theming.deleteTheming"];

export const viewEmployees = ["Rights.Management.EmployeeManagement.Employees.viewEmployees"];
export const addEmployees = ["Rights.Management.EmployeeManagement.Employees.addEmployees"];
export const updateEmployees = ["Rights.Management.EmployeeManagement.Employees.updateEmployees"];
export const deleteEmployees = ["Rights.Management.EmployeeManagement.Employees.deleteEmployees"];
export const updateEmployeeRoles = ["Rights.Management.EmployeeManagement.Employees.updateEmployeeRoles"];
export const changePassword = ["Rights.Management.EmployeeManagement.Employees.changePassword"];
export const changeOwnPassword = ["Rights.Management.EmployeeManagement.Employees.changeOwnPassword"];
export const editTimes = ["Rights.Management.EmployeeManagement.Employees.editTimes"];
export const monthClearing = ["Rights.Management.EmployeeManagement.Employees.monthClearing"];
export const loginHimself = ["Rights.Management.EmployeeManagement.Employees.loginHimself"];
export const loginAll = ["Rights.Management.EmployeeManagement.Employees.loginAll"];
export const loginEditTime = ["Rights.Management.EmployeeManagement.Employees.loginEditTime"];
export const clearHimself = ["Rights.Management.EmployeeManagement.Employees.clearHimself"];
export const clearAll = ["Rights.Management.EmployeeManagement.Employees.clearAll"];
export const listAllClearings = ["Rights.Management.EmployeeManagement.Employees.listAllClearings"];
export const undoClearing = ["Rights.Management.EmployeeManagement.Employees.undoClearing"];
export const viewRoles = ["Rights.Management.EmployeeManagement.Roles.viewRoles"];
export const allSetup = ["Rights.Management.Settings.Setup.allSetup"];
export const allLicense = ["Rights.Management.Settings.License.allLicense"];
export const allDefaultPriceGroup = ["Rights.Management.Settings.DefaultPriceGroup.allDefaultPriceGroup"];
export const viewDefaultPriceGroup = ["Rights.Management.Settings.DefaultPriceGroup.viewDefaultPriceGroup"];
export const viewMediathek = ["Rights.Management.Settings.Mediathek.viewMediathek"];
export const addRoles = ["Rights.Management.EmployeeManagement.Roles.addRoles"];
export const updateRoles = ["Rights.Management.EmployeeManagement.Roles.updateRoles"];
export const deleteRoles = ["Rights.Management.EmployeeManagement.Roles.deleteRoles"];
export const viewPriceGroupsImportExport = [
    "Rights.Management.CheckoutManagement.PriceGroups.viewPriceGroupsImportExport",
];
export const addPriceGroupsImportExport = [
    "Rights.Management.CheckoutManagement.PriceGroups.addPriceGroupsImportExport",
];
export const updatePriceGroupsImportExport = [
    "Rights.Management.CheckoutManagement.PriceGroups.updatePriceGroupsImportExport",
];
export const deletePriceGroupsImportExport = [
    "Rights.Management.CheckoutManagement.PriceGroups.deletePriceGroupsImportExport",
];
export const viewCustomers = ["Rights.Customers.viewCustomers"];
export const addCustomers = ["Rights.Customers.addCustomers"];
export const updateCustomers = ["Rights.Customers.updateCustomers"];
export const deleteCustomers = ["Rights.Customers.deleteCustomers"];
export const allMenuCardAdministration = [
    "Rights.Management.KitchenManagement.MenuCardAdministration.allMenuCardAdministration",
];
export const viewArticleGroups = [
    "Rights.Management.KitchenManagement.MenuCardAdministration.ArticleGroups.viewArticleGroups",
];
export const addArticleGroups = [
    "Rights.Management.KitchenManagement.MenuCardAdministration.ArticleGroups.addArticleGroups",
];
export const updateArticleGroups = [
    "Rights.Management.KitchenManagement.MenuCardAdministration.ArticleGroups.updateArticleGroups",
];
export const deleteArticleGroups = [
    "Rights.Management.KitchenManagement.MenuCardAdministration.ArticleGroups.deleteArticleGroups",
];
export const viewArticles = ["Rights.Management.KitchenManagement.MenuCardAdministration.Articles.viewArticles"];
export const addArticles = ["Rights.Management.KitchenManagement.MenuCardAdministration.Articles.addArticles"];
export const updateArticles = ["Rights.Management.KitchenManagement.MenuCardAdministration.Articles.updateArticles"];
export const deleteArticles = ["Rights.Management.KitchenManagement.MenuCardAdministration.Articles.deleteArticles"];
export const viewArticleLinks = [
    "Rights.Management.KitchenManagement.MenuCardAdministration.ArticleLinks.viewArticleLinks",
];
export const addArticleLinks = [
    "Rights.Management.KitchenManagement.MenuCardAdministration.ArticleLinks.addArticleLinks",
];
export const editArticleLinks = [
    "Rights.Management.KitchenManagement.MenuCardAdministration.ArticleLinks.editArticleLinks",
];
export const deleteArticleLinks = [
    "Rights.Management.KitchenManagement.MenuCardAdministration.ArticleLinks.deleteArticleLinks",
];
export const viewWeekyPlanTemplates = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlanTemplates.viewWeekyPlanTemplates",
];
export const addWeeklyPlanTemplates = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlanTemplates.addWeeklyPlanTemplates",
];
export const editWeeklyPlanTemplates = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlanTemplates.editWeeklyPlanTemplates",
];
export const deleteWeeklyPlanTemplates = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlanTemplates.deleteWeeklyPlanTemplates",
];
export const viewWeeklyPlanComponents = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlanComponents.viewWeeklyPlanComponents",
];
export const addWeeklyPlanComponents = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlanComponents.addWeeklyPlanComponents",
];
export const editWeeklyPlanComponents = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlanComponents.editWeeklyPlanComponents",
];
export const deleteWeeklyPlanComponents = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlanComponents.deleteWeeklyPlanComponents",
];
export const viewWeeklyPlanSamples = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlanSamples.viewWeeklyPlanSamples",
];
export const addWeeklyPlanSamples = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlanSamples.addWeeklyPlanSamples",
];
export const editWeeklyPlanSamples = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlanSamples.editWeeklyPlanSamples",
];
export const deleteWeeklyPlanSamples = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlanSamples.deleteWeeklyPlanSamples",
];
export const viewWeeklyPlans = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlans.viewWeeklyPlans",
];
export const addWeeklyPlans = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlans.addWeeklyPlans",
];
export const editWeeklyPlans = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlans.editWeeklyPlans",
];
export const deleteWeeklyPlans = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlans.deleteWeeklyPlans",
];
export const uploadWeeklyPlans = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlans.uploadWeeklyPlans",
];
export const anyWeeklyPlanAdministration = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.anyWeeklyPlanAdministration",
];
export const printWeeklyPlans = [
    "Rights.Management.KitchenManagement.WeeklyPlanAdministration.WeeklyPlans.printWeeklyPlans",
];
export const viewRecipes = ["Rights.Kitchen.Recipes.viewRecipes"];
export const addRecipes = ["Rights.Kitchen.Recipes.addRecipes"];
export const editRecipes = ["Rights.Kitchen.Recipes.editRecipes"];
export const deleteRecipes = ["Rights.Kitchen.Recipes.deleteRecipes"];
export const printRecipes = ["Rights.Kitchen.Recipes.printRecipes"];

export const articleSynchronization = ["Rights.Management.SyncManagement.articleSynchronization"];
export const weeklyPlanSynchronization = ["Rights.Management.SyncManagement.weeklyPlanSynchronization"];
export const viewDashboards = ["Rights.Dashboards.viewDashboards"];
export const editDashboards = ["Rights.Dashboards.editDashboards"];
export const viewPackings = ["Rights.Orders.Packings.viewPackings"];
export const editPackings = ["Rights.Orders.Packings.editPackings"];
export const viewReports = ["Rights.Dashboards.viewReports"];
export const viewCookingProcesses = ["Rights.Kitchen.CookingProcesses.viewCookingProcesses"];
export const createCookingProcesses = ["Rights.Kitchen.CookingProcesses.createCookingProcesses"];
export const editCookingProcesses = ["Rights.Kitchen.CookingProcesses.editCookingProcesses"];
export const deleteCookingProcesses = ["Rights.Kitchen.CookingProcesses.deleteCookingProcesses"];
export const skipTimer = ["Rights.Kitchen.CookingProcesses.skipTimer"];
export const viewZipCodes = ["Rights.Management.CheckoutManagement.ZipCodes.viewZipCodes"];
export const addZipCodes = ["Rights.Management.CheckoutManagement.ZipCodes.addZipCodes"];
export const updateZipCodes = ["Rights.Management.CheckoutManagement.ZipCodes.updateZipCodes"];
export const deleteZipCodes = ["Rights.Management.CheckoutManagement.ZipCodes.deleteZipCodes"];
export const viewPaymentTypes = ["Rights.Management.CheckoutManagement.PaymentTypes.viewPaymentTypes"];
export const addPaymentTypes = ["Rights.Management.CheckoutManagement.PaymentTypes.addPaymentTypes"];
export const updatePaymentTypes = ["Rights.Management.CheckoutManagement.PaymentTypes.updatePaymentTypes"];
export const deletePaymentTypes = ["Rights.Management.CheckoutManagement.PaymentTypes.deletePaymentTypes"];
export const viewCountries = ["Rights.Management.CheckoutManagement.ZipCodes.viewCountries"];
export const addCountry = ["Rights.Management.CheckoutManagement.ZipCodes.addCountry"];
export const updateCountry = ["Rights.Management.CheckoutManagement.ZipCodes.updateCountry"];
export const deleteCountry = ["Rights.Management.CheckoutManagement.ZipCodes.deleteCountry"];
export const allCourses = ["Rights.Management.CheckoutManagement.Courses.allCourses"];
export const viewCourses = ["Rights.Management.CheckoutManagement.Courses.viewCourses"];
export const addCourses = ["Rights.Management.CheckoutManagement.Courses.addCourses"];
export const updateCourses = ["Rights.Management.CheckoutManagement.Courses.updateCourses"];
export const deleteCourses = ["Rights.Management.CheckoutManagement.Courses.deleteCourses"];

export const allStoreTimes = ["Rights.Management.CheckoutManagement.StoreTimes.allStoreTimes"];
export const viewStoreTimes = ["Rights.Management.CheckoutManagement.StoreTimes.viewStoreTimes"];
export const addStoreTimes = ["Rights.Management.CheckoutManagement.StoreTimes.addStoreTimes"];
export const updateStoreTimes = ["Rights.Management.CheckoutManagement.StoreTimes.updateStoreTimes"];
export const deleteStoreTimes = ["Rights.Management.CheckoutManagement.StoreTimes.deleteStoreTimes"];

export const viewTse = ["Rights.Management.CheckoutManagement.Tse.viewTse"];
export const addTse = ["Rights.Management.CheckoutManagement.Tse.addTse"];
export const updateTse = ["Rights.Management.CheckoutManagement.Tse.updateTse"];
export const tseExport = ["Rights.Management.CheckoutManagement.Tse.tseExport"];

export const allNutrition = ["Rights.Management.KitchenManagement.Nutrition.allNutrition"];
export const nutritionAssignment = ["Rights.Management.KitchenManagement.Nutrition.changeNutritionAssignment"];

export const allOffers = ["Rights.Offers.allOffers"];
export const allOfferManagement = ["Rights.Offers.OfferManagement.allOfferManagement"];
export const viewOffers = ["Rights.Offers.OfferManagement.viewOffers"];
export const createOffers = ["Rights.Offers.OfferManagement.createOffers"];
export const editOffers = ["Rights.Offers.OfferManagement.editOffers"];
export const deleteOffers = ["Rights.Offers.OfferManagement.deleteOffers"];
