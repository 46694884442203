import { LoadIndicator } from "devextreme-react";
import React, { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Content from "./content/content";
import Header from "./header/header";

const Layout = memo(() => {
    const [languageSet, setLanguageSet] = useState(false);
    const { i18n } = useTranslation(["dynamicTranslation"]);
    const identity = useSelector((state) => state.identity);

    useEffect(() => {
        const initializeLanguage = async () => {
            if (identity.user && identity.user.Username != null && identity.user.selectedLanguage) {
                await i18n.changeLanguage(identity.user.selectedLanguage);
            }
            setLanguageSet(true);
        };

        initializeLanguage();
    }, [identity.user, i18n]);

    return (
        <React.Fragment>
            {languageSet ? (
                <React.Fragment>
                    <Header />
                    <Content />
                </React.Fragment>
            ) : (
                <div id="themeLoading">
                    <LoadIndicator width={80} height={80} />
                </div>
            )}
        </React.Fragment>
    );
});

Layout.displayName = "Layout";
export default Layout;
