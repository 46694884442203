import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Star from "@material-ui/icons/Star";
import { List } from "devextreme-react";
import $ from "jquery";
import React, { useState, useEffect, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "./navigation.scss";

const Navigation = memo(({ toggleNavigation, open }) => {
    const [navigationMenu, setNavigationMenu] = useState(undefined);
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation(["dynamicTranslation"]);

    const navigation = useSelector((state) => state.navigation);
    const user = useSelector((state) => state.identity.user);

    const goToPage = useCallback(
        (path) => {
            history.push(path);
        },
        [history]
    );

    const buildNavigationSubMenus = useCallback(
        (navigationMenu, allNavigationTiles) => {
            navigationMenu.forEach((navigationTile) => {
                if (navigation.pages.some((page) => page.title === navigationTile.title && page.isNavigationPage)) {
                    navigationTile.isNavigationPage = true;
                    navigationTile.navigationTiles = allNavigationTiles.filter(
                        (tile) => tile.hasRight && tile.navGroup === navigationTile.title
                    );
                    buildNavigationSubMenus(navigationTile.navigationTiles, allNavigationTiles);
                }
            });
        },
        [navigation.pages]
    );

    const buildNavigationMenu = useCallback(
        (allNavigationTiles) => {
            const navigationMenu = navigation.pages[0].navigationTiles.filter(
                (tile) =>
                    tile.hasRight &&
                    (!tile.hideTile ||
                        tile.title === "Navigation" ||
                        tile.title === "Zurück" ||
                        tile.title === "Startseite")
            );
            buildNavigationSubMenus(navigationMenu, allNavigationTiles);
            return navigationMenu;
        },
        [navigation.pages, buildNavigationSubMenus]
    );

    useEffect(() => {
        let allNavigationTiles = [];
        const favorites = user.Favorites;
        let favoriteTiles = [];

        navigation.pages.forEach((page) => {
            if (page.navigationTiles) {
                allNavigationTiles = allNavigationTiles.concat(page.navigationTiles);
            }
        });

        if (Array.isArray(favorites)) {
            favoriteTiles = allNavigationTiles.filter((tile) =>
                favorites.some((element) => element.route === tile.route)
            );
        }

        if (favoriteTiles.length > 0) {
            favoriteTiles = favoriteTiles.map((tile) => ({
                ...tile,
                isFavorite: true,
            }));
        }

        const newNavigationMenu = buildNavigationMenu(allNavigationTiles);
        newNavigationMenu.push({
            title: "Favoriten",
            navGroup: "Home",
            hasRight: true,
            icon: Star,
            navigationTiles: favoriteTiles,
            isNavigationPage: true,
        });
        setNavigationMenu(newNavigationMenu);
    }, [user.Favorites, navigation.pages, buildNavigationMenu]);

    const navigationListItem = useCallback(
        (navigationTile, index, recursionDepth) => {
            const mediumIconStyle = { marginRight: 5, width: "24px", height: "24px", fontSize: "24px" };
            const largeIconStyle = { marginRight: 5, width: "36px", height: "36px", fontSize: "36px" };

            if (!navigationTile.isNavigationPage) {
                return (
                    <div>
                        <span
                            onClick={() => navigationTile.route && goToPage(navigationTile.route)}
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                            title={t(navigationTile.title)}
                        >
                            <navigationTile.icon
                                icon={navigationTile.iconText || ""}
                                style={recursionDepth === 0 ? largeIconStyle : mediumIconStyle}
                            />
                            <span style={{ marginLeft: 15 }}>{t(navigationTile.title)}</span>
                        </span>
                    </div>
                );
            }

            return (
                <div>
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                        }}
                        title={t(navigationTile.title)}
                        onClick={() => {
                            $(`.navItem_${recursionDepth}_${index}`).toggleClass("open");
                        }}
                    >
                        <navigationTile.icon
                            fontSize={recursionDepth === 0 ? "large" : "medium"}
                            icon={navigationTile.iconText || ""}
                            size="lg"
                            style={recursionDepth === 0 ? largeIconStyle : mediumIconStyle}
                        />
                        <span style={{ marginLeft: 15 }}>{t(navigationTile.title)}</span>
                        <span style={{ position: "absolute", right: 5 }}>
                            <KeyboardArrowDown style={{ fontSize: "24px" }} />
                        </span>
                    </span>

                    <div className={`navContainer navItem_${recursionDepth}_${index}`}>
                        {open && nestedNavigationList(navigationTile, recursionDepth)}
                    </div>
                </div>
            );
        },
        [t, open, goToPage]
    );

    const nestedNavigationList = useCallback(
        (navigationTile, recursionDepth) => {
            return (
                <List
                    virtualModeEnabled
                    items={navigationTile.navigationTiles}
                    onItemClick={(e) => {
                        if (!e.itemData.isNavigationPage) {
                            toggleNavigation();
                            goToPage(e.itemData.route);
                        }
                    }}
                    style={{ marginLeft: 20 }}
                    expandAllEnabled={false}
                    itemRender={(item, idx) => navigationListItem(item, idx, recursionDepth + 1)}
                />
            );
        },
        [toggleNavigation, goToPage, navigationListItem]
    );

    if (!user.Token) {
        return null;
    }

    return (
        <div id="navigation" style={{ width: "350px", boxShadow: "0px 0px 5px #000", backgroundColor: "#eee" }}>
            {navigationMenu && (
                <List
                    items={navigationMenu}
                    onItemRendered={(e) => {
                        if (e.itemData.route === location.pathname) {
                            $(e.itemElement).toggleClass("active-route");
                        }
                    }}
                    onItemClick={(e) => {
                        switch (e.itemData.title) {
                            case "Navigation":
                                toggleNavigation();
                                break;
                            case "Zurück":
                                if (window.location.hash.includes("/kochprozess/")) {
                                    goToPage("/kueche/kochprozesse/p");
                                } else {
                                    history.goBack();
                                }
                                break;
                            case "Startseite":
                                goToPage("/");
                                break;
                            default:
                                if (e.itemData.isNavigationPage && !open) {
                                    goToPage(e.itemData.route);
                                }
                        }
                    }}
                    activeStateEnabled={false}
                    focusStateEnabled={false}
                    itemRender={(item, idx) => navigationListItem(item, idx, 0)}
                />
            )}
        </div>
    );
});

Navigation.displayName = "Navigation";
export default Navigation;
