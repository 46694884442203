export const SET_NEW_ORDERS_AVAILABLE = "SET_NEW_ORDERS_AVAILABLE";
export const UNSET_NEW_ORDERS_AVAILABLE = "UNSET_NEW_ORDERS_AVAILABLE";

export function setNewOrdersAvailable() {
    return {
        type: SET_NEW_ORDERS_AVAILABLE,
    };
}

export function unsetNewOrdersAvailable(orders) {
    return {
        type: UNSET_NEW_ORDERS_AVAILABLE,
    };
}
