import React, { useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentPage } from "../../../../actions/pagesActions";
import NavigationPage from "./navigationPage";

const DynamicPage = memo(({ page, changePage, ...props }) => {
    const { t } = useTranslation(["dynamicTranslation"]);
    const settings = useSelector((state) => state.settings);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentPage(page));
    }, [dispatch, page]);

    if (page.isNavigationPage) {
        return <NavigationPage page={page} changePage={changePage} />;
    }

    return <page.component {...props} settings={settings} />;
});

DynamicPage.displayName = "DynamicPage";
export default DynamicPage;
