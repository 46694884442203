import Config from "../../Config";
import { authHeader, handleResponse, translateResponse } from "../../helpers/requestHelpers";
import { clearShopCache } from "../settingActions";

const config = new Config();

export const REQUEST_ARTICLEGROUPS = "REQUEST_ADDARTICLEGROUPS";
export function requestArticleGroups() {
    return {
        type: REQUEST_ARTICLEGROUPS,
    };
}

export const RECEIVE_ARTICLEGROUPS = "RECEIVE_ADDARTICLEGROUPS";
export function receiveArticleGroups(json) {
    return {
        type: RECEIVE_ARTICLEGROUPS,
        articleGroups: json,
    };
}

/*Diese Funktion lädt alle sichtbaren Artikelgruppen*/
export function fetchArticleGroups() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        dispatch(requestArticleGroups());
        return fetch(config.backendHost + "/ArticleGroups/GetArticleGroups", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveArticleGroups(json)));
    };
}

export function postArticleGroup(name, parentId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ name: name, articleGroupsId: parentId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/ArticleGroups/AddNewArticleGroup", requestOptions)
            .then((response) => {
                if (response.ok) {
                    return "ok";
                } else {
                    return response.text();
                }
            })
            .then((response) => {
                if (response !== "ok") {
                    throw new Error(response);
                }
            });
    };
}

export function updateArticleGroup(key, values) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articleGroupsId: key, articleGroup: values }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/ArticleGroups/update", requestOptions).then((response) => {
            dispatch(clearShopCache("getArticlesForOnlineShop"));
            dispatch(clearShopCache("getRecommendationsForOnlineShop"));
            dispatch(clearShopCache("getCurrentRecommendationsForOnlineShop"));
            return handleResponse(response);
        });
    };
}

export function fetchArticleGroupDummyList(t) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/ArticleGroups/getDummyList", requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => translateResponse(response, t));
    };
}

export function getDummyListArticle(parentIds, hideDeletedArticles, t) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify({ parentIds: parentIds, hideDeletedArticles: hideDeletedArticles }),
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/ArticleGroups/getDummyListArticles", requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => translateResponse(response, t));
    };
}

export function getFilteredDummyListArticles(filterValue, hideDeleted, t) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify({ filterString: filterValue, hideDeletedArticles: hideDeleted }),
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/ArticleGroups/getFilteredDummyListArticles", requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => translateResponse(response, t));
    };
}

export function fetchArticleGroupDummyListSync() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/ArticleGroups/getDummyListSync", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function copyArticleGroup(toCopyArticleGroupsId, newParentArticleGroup) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
            toCopyArticleGroupsId: toCopyArticleGroupsId,
            newParentArticleGroup: newParentArticleGroup,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/ArticleGroups/CopyArticleGroup", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
